import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from 'react';
import "../styles/scss/common/header.scss"
import Categories from "./menu/categories";
import TopBanks from "./menu/top-banks";
import TopStores from "./menu/top-stores";

class Header extends Component {
	state = { showMenu: false }
	toggleMenu = () => {
		this.setState({
			showMenu: !this.state.showMenu
		})
	}
	render() {
		const menuActive = this.state.showMenu ? 'is-active' : '';
		const burgerActive = this.state.showMenu ? 'is-active' : '';
		return (
			<header className={` ${burgerActive}`}>
				<div className="container">
					<div className="mob-nav" onClick={this.toggleMenu}>
						<div className="bar1"></div>
						<div className="bar2"></div>
						<div className="bar3"></div>
					</div>
					<div className="left-nav">
						<Link to="/" title="HappyCredit" className="logo"><img src="https://happycredit.in/static/images/hc-logo.svg" alt="HappyCredit Logo" /></Link>
						<form action="/search/" method="get">
						<label htmlFor="search">
							<input type="search" id="search" placeholder="Enter your search ..." name="q" />
							<input type="submit" aria-label="Search" />
							</label>
						</form>
					</div>
					<span className="close" onClick={this.toggleMenu}></span>
					<nav className={` ${menuActive}`}>
						<Categories />
						<TopBanks />
						<TopStores />
						{/*<Link to="/login/" className="login">Login </Link> */}
					</nav>
				</div>
			</header>)
	}
};

export default Header