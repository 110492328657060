import { Link } from "gatsby"
import React from 'react';
import { StaticQuery, graphql } from "gatsby";

const CategoriesMenu  = ({ data }) => (
	<div className="category cat-links">
		<span>Categories </span>
		<div className="dropdown dis-links">
			<div className="left-clm">		
				{data.hc.categories.map(c => (
					<Link key={c.id} to={c.page.url}>{c.name}</Link>
				))}
				<Link to="/all-categories/">All Categories</Link>
			</div>
		</div>
	</div>
)

export const Categories = props => (
  <StaticQuery
    query={graphql`
	  query {
		hc {
			categories (orderBy: popularityScore_DESC) {
				id
				name
				page{id url}
			}
		}
	  }
    `}
    render={data => <CategoriesMenu {...props} data={data} />}
  />
)

export default Categories