import { Link } from "gatsby"
import React from "react"
import "./../styles/scss/common/footer.scss"
import TopBanks from "./menu/top-banks";
import Categories from "./menu/categories";
import TopStores from "./menu/top-stores";

const Footer = () => {
	var d = Date(); 
	var updated = d.toString();
	
  return (
	<footer>
		<div className="container">
			<div className="clm-wrapper">
				<div className="col-md brnd-logo">
					<Link className="brand" to="/"><img src={'/images/footer-logo.png'} title="HappyCredit" alt="HappyCredit Logo" /></Link>
					<span>Get rewarded! Get credited!</span>
				</div>
				<div className="col-md">
					<h3>Company</h3>
					<Link to="/contact-us/#about-us">About Us</Link>
					{/*<Link to="#">Our Team</Link>*/}
					<Link to="/contact-us/#contact-us">Contact Us</Link>
				</div>
				<div className="col-md">
					<h3>Useful Links</h3>
					{/*<Link to="#">Help &amp; Support</Link>*/}
					<Link to="/terms/">Terms &amp; Privacy Policy</Link>
					{/*<Link to="#">Refer &amp; Earn</Link> */}
				</div>
				<div className="col-md social">
					<h3>Follow us on Social Media</h3>
					<a href="https://www.facebook.com/HappyCreditApp" className="fb" title="facebook Profile" target="_blank" rel="noopener noreferrer">
						<img src={'/images/fb.svg'}title="Facebook" alt="Facebook Icon" />
						Facebook
					</a>
					<a href="https://twitter.com/HappyCreditApp/" className="tw" title="twitter Profile" target="_blank" rel="noopener noreferrer">
						<img src={'/images/tw.svg'} title="twitter" alt="twitter Icon" />
						Twitter
					</a>
					<a href="https://www.instagram.com/happycreditapp/" className="inst" title="Instagram Profile" target="_blank" rel="noopener noreferrer">
						<img src={'/images/instagram.svg'} title="Instagram" alt="Instagram Icon" />
						Instagram
					</a>
				</div>
				<div className="footer-dropdown">
					<div className="footer-drop-wrapper">
						<TopBanks />
					</div>
					<div className="footer-drop-wrapper">
						<Categories />
					</div>
					<div className="footer-drop-wrapper">
						<TopStores />
					</div>
					<div className="bank-list-link">
						<Link to="/bank-lists-pages/">Bank Lists Pages</Link>
					</div>
				</div>
			</div>
			<div className="copy-rights">© {new Date().getFullYear()}, HappyCredit (CyberGenes Innovations Pvt. Ltd.)<span className="hide">{"Updated on : " + updated}</span></div>
		</div>
	</footer>
)}


export default Footer