import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

function SEO({ page }) {
	let month = new Date();
	month = month.toLocaleDateString("en-US", { month: 'long', year:'numeric' });

	let hide_month = !!page.hide_month;
	
	let title = page.title;
	if (!hide_month){ title += " : " + month ;}
	title += " | HappyCredit";
	
	let desc = page.description;
	let canon = "https://happycredit.in" + page.canonicalUrl;
	let og_image = "https://happycredit.in/static/images/social/og_image.png";
	let og_favicon = "https://happycredit.in/static/images/google_favicon_48.png";
	
	return (
		<Helmet>
			<html lang="en" />
			<title>{title}</title>
			<meta name="description" content={desc} />
			<link rel="canonical" href={canon} />
			<meta name="robots" content={page.robots} />
			
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canon} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content={og_image} />
			
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@HappyCreditApp" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={desc} />
            <meta name="twitter:image" content={og_image} />
			<link rel="shortcut icon" href={og_favicon} />
		</Helmet>
	)
}

export default SEO
