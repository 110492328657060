import { Link } from "gatsby"
import React from 'react';
import { StaticQuery, graphql } from "gatsby";

const TopBanksMenu  = ({ data }) => (
	<div className="category top-banks">
		<span >Top Banks </span>
		<div className="dropdown dis-links">
			<div className="left-clm">
				{data.hc.banks.map(c => (
					<Link key={c.id} to={c.page.url}>{c.name}</Link>
				))}
				<Link to="/all-banks/">All Banks</Link>
			</div>
		</div>
	</div>
)

export const TopBanks = props => (
  <StaticQuery
    query={graphql`
	  query {
		hc {
			banks (orderBy: popularityScore_DESC, first:14) {
				id
				name
				page{id url}
			}
		}
	  }
    `}
    render={data => <TopBanksMenu {...props} data={data} />}
  />
)

export default TopBanks