import { Link } from "gatsby"
import React from 'react';
import { StaticQuery, graphql } from "gatsby";

const TopStoresMenu  = ({ data }) => (
	<div className="category top-stores-links">
		<span >Top Stores </span>
		<div className="dropdown dis-links">
			<div className="left-clm">
				{data.hc.stores.map(c => (
					<Link key={c.id} to={c.page.url}>{c.name}</Link>
				))}
				<Link to="/all-stores/">All Stores</Link>
			</div>
		</div>
	</div>
)

export const TopStores = props => (
  <StaticQuery
    query={graphql`
	  query {
		hc {
			stores (orderBy: popularityScore_DESC, first:14) {
				id
				name
				page{id url}
			}
		}
	  }
    `}
    render={data => <TopStoresMenu {...props} data={data} />}
  />
)

export default TopStores