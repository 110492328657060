import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"

const Layout = ({ children, page }) => {

  return (
    <>
	  <SEO page={page} />
      <Header />
      <div>
        
		<main>{children}</main>
		
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout